/* eslint-disable no-param-reassign */
class ServiceAreaCountry {
  static call() {
    document.querySelectorAll('.js-contact-info').forEach((item) => new ServiceAreaCountry(item));
  }

  constructor(item) {
    this.item = item;

    this.bindEvents();
    this.addListeners();
  }

  bindEvents() {
    this.handleChange = this.handleChange.bind(this);
  }

  addListeners() {
    this.item.addEventListener('change', this.handleChange);
  }

  handleChange(event) {
    const countryCode = event.currentTarget.value;
    const contactInfo = JSON.parse(event.currentTarget.dataset.contactInfo);

    const countryData = contactInfo[countryCode] || this.blankContactInfo();

    this.updateFields(countryData);
  }

  updateFields(data) {
    document
      .getElementsByName('service_area[address]')
      .forEach((element) => (element.value = data.address));
    document
      .getElementsByName('service_area[email]')
      .forEach((element) => (element.value = data.email));
    document
      .getElementsByName('service_area[phone_number]')
      .forEach((element) => (element.value = data.phone_number));
  }

  blankContactInfo() {
    return { address: null, email: null, phone_number: null };
  }
}

document.addEventListener('sidebarLoaded', ServiceAreaCountry.call);
